var site = site || {};
site.account = site.account || {};

$(document).ready(function() {
  site.account.initPasswordValidationTooltip();
});

site.account.getPassDOMNodes = function() {
  site.account.passwordInfo = $('#loyalty_registration_form .js-password-field__info');
};

site.account.validatePasswordRules = function() {
  site.account.passwordInfo.removeClass('hidden');
  var $input = $(this),
      value = this.value,
      $rulesItems = $input.closest('.form-item').find('.js-password-field__rules').find('li'),
      rules = {
        'contains_lower_case_chars': /[a-z]/,
        'contains_uppercase_chars': /[A-Z]/,
        'contains_numbers': /[0-9]/,
        'minimum_length': /.{8,}/
      };
  if ($input.closest('.form-item').find('ul.js-password-field__rules li.special_character').length > 0) {
    rules.contains_special_character = /[^\w\d|]/;
  }
  // mark the input field if it has any value
  $input.toggleClass('hasValue', this.value);
  // iterate all the rules and validate them
  $.each($rulesItems, function() {
    var valid,
        $elem = $(this),
        ruleName = $elem.data('rule');

    if (rules[ruleName]) {
      valid = rules[ruleName].test(value);
      if (ruleName === 'contains_special_character' && valid && (/[|_]/u).test(value)) {
        valid = !valid;
      }
      $elem.toggleClass('pass', valid);
    }
  });
};

site.account.initPasswordValidationTooltip = function() {
  var isMobile = site.client.isMobile;
  var $passwordField = $('#loyalty_registration_form .js-password-field');
  var isiPadPC = Drupal.ELB.ui.mq.medium_up;
  site.account.getPassDOMNodes();
  if (isMobile) {
    site.account.passwordInfo.removeClass('hidden');
  }
  if (isiPadPC && $('.js-signin-overlay-loyalty').length) {
    site.account.passwordInfo.addClass('hidden');
  }
  $('input[type="text"],input[type="password"]', $passwordField).on('input focus', site.account.validatePasswordRules);
  $('input[type="text"],input[type="password"]', $passwordField).bind('blur', function() {
    site.account.passwordInfo.addClass('hidden');
    if (isMobile) {
      site.account.passwordInfo.removeClass('hidden');
    }
    if (isiPadPC && $('.js-signin-overlay-loyalty').length) {
      site.account.passwordInfo.addClass('hidden');
    }
  });
};
